.footer-container {
        width: 100%;
        min-height: 18.5vh;
        height: fit-content;
        background-color: #006de9;
        margin-top: 3.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        font-weight: light;
}

.footer-info-container {
        width: 60%;
        min-width: 65rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        overflow: hidden;
        font-size: 1rem;
        font-weight: light;
}

.footer-info-container p {
        margin-bottom: .5rem;
}

.footer-title-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2rem;
        font-size: 1rem;
        font-weight: 200;
        margin: 0;
}

.footer-title {
        font-size: 1rem;
        font-weight: normal;
}

.footer-icon {
        width: 1.5rem;
        margin-right: .5rem;
}

@media only screen and (max-width: 1200px) {
        .footer-info-container {
                justify-content: space-around;
        }
}

@media only screen and (max-width: 1100px) {
        .footer-info-container {
                justify-content: space-around;
                min-width: 80%;
        }

        .footer-info-container div {
                min-width: 35%;
                margin-top: 3rem;
        }

        .footer-info-container div:nth-child(3) {
                margin-bottom: 3rem;
        }

        .footer-info-container div:nth-child(4) {
                margin-bottom: 3rem;
        }

        .footer-title-container {
                display: flex;
                justify-content: flex-start
        }
}

@media only screen and (max-width: 650px) {
        .footer-info-container {
                flex-direction: column;
        }

        .footer-info-container div:nth-child(3) {
                margin-bottom: 0;
        }
}