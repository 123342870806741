* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
}

body {
    background-color: #fcfcfc;
    color: #ebebeb;
}

li {
    list-style: none;
}