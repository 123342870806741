.homepage-container {
        background-color: #eef9ff;
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        top: 3.5em;
}

.welcome-container {
        position: relative;
        background-color: #eef9ff;
        width: 100%;
        min-height: calc(100vh - 3.5em);
        max-height: calc(100vh - 3.5em);
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 3rem;
        padding-bottom: 3rem;
        color: #005bc4;
}

.welcome-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-right: 2rem;
        margin-left: 2rem;
        width: 30%;
        min-width: 30rem;
        text-align: start;
}

.welcome-title {
        color: #005bc4;
        margin-bottom: 1.5rem;
        font-size: 3rem;
}

.welcome-title-main-words {
        color: #0e7eff;
}

.welcome-text {
        margin-bottom: 1.5rem;
        font-size: 1.5rem;
        font-weight: 400;
}

.welcome-text-container button {
        width: 50%;
        height: 3.5rem;
}

.welcome-img {
        width: 40%;
        min-width: 40rem;
        margin-right: 2rem;
}

.scroll-tutorial {
        position: absolute;
        justify-self: center;
        align-self: flex-end;
        width: 6rem;
        font-size: 2.5rem;
        color: #e9e9e9;
        background-color: #adadad;
        opacity: .6;
        cursor: pointer;
        bottom: 0;
}

.tutorial-block {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 4rem;
        padding-bottom: 4rem;
}

.tutorial-block-odd {
        background-color: #005bc4;
        color: #eee;
        
}

.tutorial-block-even {
        color: #3e3e3e;
}

.tutorial-text-img-container {
        min-width: 50rem;
        width: 50%;
        opacity: 0;
        transition: opacity 0.7s ease-in-out, transform 0.7s ease-in-out;
}

.tutorial-text-img {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
}

.tutorial-block-odd .tutorial-text-img-container {
        transform: translateX(-50px);
}

.tutorial-block-even .tutorial-text-img-container {
        transform: translateX(50px);
}

.tutorial-block .tutorial-visible {
        display: block;
        opacity: 1;
        transform: translateX(0);
}

.tutorial-text-img h2 {
        width: 20rem;
}

.tutorial-img {
        width: 50%;
}

@media only screen and (max-width: 1200px) {
        .welcome-title {
                font-size: 2.5rem;
        }
        
        .welcome-text {
                font-size: 1.4rem;
        }
        
        .welcome-img {
                min-width: 30rem;
        }
}

@media only screen and (max-width: 1040px) {
        .welcome-container {
                flex-direction: column;
        }

        .welcome-title {
                font-size: 2.7rem;
                margin-bottom: 1rem;
        }
        
        .welcome-text-container button {
                align-self: center;
                width: 60%;
                margin-bottom: 2rem;
        }

        .scroll-tutorial {
                left: calc(50vw - 3rem)
        }
}

@media only screen and (max-width: 1000px) {
        .tutorial-text-img {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
        }

        .tutorial-block h2 {
                margin-bottom: 3rem;
                padding-left: 0;
        }

        .tutorial-block-even h2 {
                margin-top: 3rem;
                margin-bottom: 0rem;
        }

        .tutorial-block-odd .tutorial-text-img-container {
                transform: translateX(0);
        }
        
        .tutorial-block-even .tutorial-text-img-container {
                transform: translateX(0);
        }
}

@media only screen and (max-width: 820px) {
        .tutorial-text-img-container {
                min-width: 100%;
        }
}

@media only screen and (max-width: 660px) {
        .welcome-text-container {
                min-width: 80%;
        }

        .tutorial-img {
                min-width: 21rem;
        }
}

@media only screen and (max-width: 550px) {
        .welcome-text-container {
                min-width: 80%;
        }

        .welcome-title {
                font-size: 2.3rem;
                margin-right: 0;
        }

        .welcome-text {
                font-size: 1.3rem;
                margin-bottom: 3rem;
        }

        .welcome-text-container button {
                width: 100%;
                margin-bottom: 3rem;
        }

        .welcome-img {
                min-width: 22rem;
                margin-right: 0;
        }
}

@media only screen and (max-width: 429px) {
        .welcome-text-container {
                min-width: 80%;
        }

        .welcome-title {
                font-size: 2.1rem;
        }

        .welcome-text {
                font-size: 1.1rem;
        }

        .welcome-img {
                min-width: 20rem;
        }
}