.linechart-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 32rem;
        height: 70vh;
        position: relative;
        transition: all 0.2s ease-in-out;
}

@media only screen and (max-width: 1000px) {
        .linechart-container {
                min-width: 93vw;
        }
}

@media only screen and (max-width: 660px) {
        .linechart-container {
          width: 90%;
          min-height: 80vh;
        }
      }

@media only screen and (max-width: 400px) {
        .linechart-container {
                min-width: 95vw;
                max-height: 34rem;
        }
}