button {
        background-color: #006de9;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 8.5rem;
        height: 3rem;
        padding: .8rem;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        font-size: 1rem;
        font-weight: normal;
        position: relative;
}

button:focus {
        outline: 0;
}

button:hover {
        background-color: #1481ff;
        cursor: pointer;
        transition: 200ms;
}

.backdrop {
        width: 100vw;
        height: 94vh;
        top: 3.5em;
        left: 0;
        background-color: rgba(0, 0, 0, 0.2);
        position: fixed;
        z-index: 4;
        transition: all .1s ease;
}
